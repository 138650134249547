import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/fontawesome';
import ErrorPage from './Components/UI/ErrorPage';
import HomePage from './Components/HomePage/HomePage';
import HUBEXTECH_TALENT_APP_ROUTES from "./Components/Services/AppRoutes";

function App() {

    return (
        <Routes>
            <Route path={HUBEXTECH_TALENT_APP_ROUTES.Error_PAGE} element={<ErrorPage/>}></Route>
            <Route exact path={HUBEXTECH_TALENT_APP_ROUTES.HOME_PAGE} element={<HomePage/>}></Route>
        </Routes>
    );
}

export default App;