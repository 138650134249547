import React, {useState} from "react";
import './ResumeSection.css';
import {Col, Container, Row} from "react-bootstrap";
import * as qs from "qs";
import HUBEXTECH_TALENT_APP_API from "../../../Services/API";
import axios from "axios";
import Card from "./ResumeCard/Card";
import {ResumeListPagination} from "./Pagination/ResumeListPagination";
import PreLoaderBeforeResponse from "../../../UI/PreLoaderBeforeResponse";

const ResumeSection = () => {
    const [loader, setLoader] = useState(true);
    const [resumeData, setResumeData] = useState('');
    const getResumeList = (pageNumber = 1) => {
        const query = qs.stringify({
            pagination: {
                page: pageNumber,
                pageSize: 12,
            }
        })
        const baseURL = `${process.env.REACT_APP_BASE_URL}${HUBEXTECH_TALENT_APP_API.RESUME_LIST}${query}${HUBEXTECH_TALENT_APP_API.DATA_SET}`;
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + process.env.REACT_APP_AUTH_KEY;
        axios.get(baseURL)
            .then(response => {
                setLoader(false)
                setResumeData(response.data);
            })
            .catch(err => {
                console.log(err)
            })
    }
    loader && getResumeList()
    return (
        <section className="section-body py-5 main">
            <Container>
                <div className="text-center">
                    <span className="fs-1 heading-bold">Our Talent</span>
                    <p className="fs-5 lh-sm px-5 pt-3">We can provide you with well-trained resources, who have been exerting their talent for
                        continuous learning, developing, and staying ahead of the curve and empowering clients with
                        world-class software and applications that help them to reach their business goals.</p>
                </div>
                {
                    !resumeData && loader ?
                        <PreLoaderBeforeResponse/>
                        :
                        <>
                            <Row className="d-flex justify-content-center w-100 py-5">
                                {resumeData?.data.map((item, index) => (
                                    <Col md={6} lg={4} xl={3} key={index} className="mb-4">
                                        <Card resumeData={item}/>
                                    </Col>
                                ))}
                            </Row>
                            <div className="d-flex justify-content-center">
                                {resumeData?.meta.pagination.total > 10 && (
                                    <ResumeListPagination paginate={getResumeList}
                                                          pagination={resumeData?.meta.pagination}/>
                                )}
                            </div>
                        </>
                }

            </Container>
        </section>
    );
}

export default ResumeSection;