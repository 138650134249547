import React from 'react';
import './Card.css';

const Card = ({resumeData}) => {
    const resumeSlug = resumeData.slug;
    const imageUrl = resumeData.image.data?.url;
    return (
        <div className="card-main">
            <div className='profile-img-side card-img-top m-auto my-3'
                 style={{backgroundImage: imageUrl ? `url(${process.env.REACT_APP_BASE_URL}${imageUrl})` : null}}></div>
            <div className="card-body">
                <p className="card-title">{resumeData.name}</p>
                <div className="d-flex justify-content-center">
                    <p className="resume-title">{resumeData.title}</p>
                </div>
                <p className="card-text mx-2 text-start overMe">{resumeData.description}</p>
                <div className="card-body-main">
                    <div className='m-2 d-flex justify-content-center flex-wrap'>
                        {
                            resumeData.technologies?.data.filter((item, index) => index < 5).map((curr, index) => {
                                return <span className='skills-title me-2 my-1' key={index}>{curr.title}</span>
                            })
                        }
                    </div>
                </div>
                <a href={`https://talent.hubextech.com/#/resumes/${resumeSlug}`} target="_blank"
                   rel="noreferrer">
                    <button className="my-3 btn-main rounded-3">
                        <span>View More</span>
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Card
