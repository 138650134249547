import React from 'react'
import "./Preloader.css";
import Logo from '../../Assets/Images/logo.png'

const Preloader = () => {

    const preLoaderData = ['Estimate', 'Design', 'Develop', 'Marketing', 'Maintenance']

    return (
        <>
            <section>
                <div className="loader-main d-flex align-items-center justify-content-center">
                    <div className="border-end border-5 border-dark pe-3 me-3 loader-sub">
                        <img src={Logo} className="preLoader-logo_img" alt="loader-logo"/>
                    </div>
                    <div className="loader">
                        {
                            preLoaderData.map((curr, index) => {
                                return <span key={index}>{curr}</span>
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
export default Preloader