import React from "react";
import './ResumeListPagination.css';
import ReactPaginate from "react-paginate";

export const ResumeListPagination = ({pagination, paginate}) => {
    const pageCount = Math.ceil(pagination.total / 12);
    const handlePageClick = (data) => {
        paginate(data.selected + 1)
    }

    return (
        <div className="d-flex">
            <ReactPaginate
                pageCount={pageCount}
                containerClassName="pagination"
                pageClassName="page"
                onPageChange={handlePageClick}
                activeLinkClassName="active_link"
                nextClassName="next"
                previousClassName="previous"
                nextLabel=""
                previousLabel=""
                renderOnZeroPageCount={null}
            />
        </div>
    );
};