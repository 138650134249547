import React, {useState} from "react";
import {Container, Navbar} from "react-bootstrap";
import HubexLogo from '../../../Assets/Images/logo.png'
import './MainNavbar.css';

const MainNavbar = () => {
    const [navClasses, setNavClasses] = useState('py-4 navbar-main');
    //Sticky Navbar]
    const handleScroll = () => {
        if (window.scrollY > 500) {
            setNavClasses('navbar-main position-fixed top-0 nav-bg w-100')
        } else {
            setNavClasses('navbar-main')
        }
    }
    window.addEventListener('scroll', handleScroll)

    return (
        <div className='sticky' style={{zIndex: 999}}>
            <Navbar expand="lg" className={navClasses}>
                <Container className="overflow-nav">
                    <div className="w-50">
                        <a href="https://www.hubextech.com/">
                            <img src={HubexLogo} className="img-fluid" width="180px" alt="logo"/></a>
                    </div>
                    <div>
                        <a href="https://calendly.com/moeez-haider-hubextech/hubextech" target="_blank" rel="noreferrer">
                            <button className="mx-2 book-slot fw-bolder">Get in Touch</button>
                        </a>
                    </div>
                </Container>
            </Navbar>
        </div>

    );
}
export default MainNavbar;