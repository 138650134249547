import React from 'react'
import "./PreloaderBeforeResponse.css";

const PreloaderBeforeResponse = () => {

    return (
        <div className='main-container'>
            <div className='outer'>
                <div className='middle'>
                    <div className='inner'></div>
                </div>
            </div>
        </div>
    );
}
export default PreloaderBeforeResponse