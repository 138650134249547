import React, {useEffect, useState} from "react";
import HeroSection from "./SubSections/HeroSection/HeroSection";
import ResumeSection from "./SubSections/Resume/ResumeSection";
import Preloader from "../UI/Preloader";
import Footer from "../Includes/Footer/Footer";

const HomePage = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])
    return (
        <div>
            {loading ? <Preloader/> :
                <>
                    <HeroSection/>
                    <ResumeSection/>
                    <Footer/>
                </>
            }
        </div>
    );
}

export default HomePage;