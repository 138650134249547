import React from "react";
import "./Footer.css"
import {Col, Container, Row} from "react-bootstrap";
import img1 from "../../../Assets/Images/socialLogo3.png"
import location from "../../../Assets/Images/location.svg"
import phone from "../../../Assets/Images/phone.svg"
import linkedIn from "../../../Assets/Images/linkedin.png"
import twitter from "../../../Assets/Images/twitter.png"
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="Footer-Card">
            <Container>
                <div className="footer-content pt-5 pb-5">
                    <Row>
                        <Col lg={4} className="mb-30">
                            <div className="footer-margin">
                                <div className="footer-Col2-heading footer-heading">
                                    <h3>Address</h3>
                                </div>
                                <a href="https://www.google.com/maps/place/4+Riverina+Blvd,+Brookfield+VIC+3338,+Australia/@-37.708397,144.545497,16z/data=!4m5!3m4!1s0x6ad693151396b1df:0xfaf6c1c475e03ae2!8m2!3d-37.7083967!4d144.545497?hl=en"
                                   target="_blank" rel="noreferrer"
                                   className="text-black text-decoration-none">
                                    <div className="d-flex mb-3 px-2 px-md-5 text-white">
                                        <img alt="location" src={location} height={30}/>
                                        <div className="fs-5 mx-3 text-white">
                                            4 Riverina boulevard, Brookfield 3338, Melbourne, Australia.
                                        </div>
                                    </div>
                                </a>
                                <a href="tel:+61426508849" target="_blank" rel="noreferrer"
                                   className="text-black text-decoration-none">
                                    <div className="d-flex align-items-center mb-4 px-2 px-md-5">
                                        <img alt="phone" src={phone} height={30}/>
                                        <div className="fs-5 mx-3 text-white">
                                            + 61 426 508 849
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="footer-widget footer-margin">
                                <div className="footer-Col1-heading footer-heading">
                                    <h3>Quick Links</h3>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <a href="https://calendly.com/moeez-haider-hubextech/hubextech"
                                       target="_blank" rel="noreferrer" className={"link-border mx-3"}>
                                        <img alt="social-link-1" src={img1} height={50} className="img-border"/>
                                    </a>
                                    {/*<div className={"link-border mx-3"}>*/}
                                    {/*    <img alt="social-link-2" src={img2} height={50} className="img-border"/>*/}
                                    {/*</div>*/}
                                    {/*<div className={"link-border mx-3"}>*/}
                                    {/*    <img alt="social-link-3" src={img3} height={50} className="img-border"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="footer-widget footer-margin">
                                <div className="footer-Col3-heading footer-heading">
                                    <h3>Connect With Us</h3>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <a href="https://www.linkedin.com/company/hubex-tech/"
                                       target="_blank" rel="noreferrer" className={"link-border mx-3"}>
                                        <img alt="social-link-4" src={linkedIn} height={50}
                                             className="img-border"/>
                                    </a>
                                    <a href="https://twitter.com/HubexT"
                                       target="_blank" rel="noreferrer" className={"link-border mx-3"}>
                                        <img alt="social-link-5" src={twitter} height={50}
                                             className="img-border"/>
                                    </a>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Container>
            <div className="copyright-section">
                <Container>
                    <Row>
                        <Col
                            className="col-xl-12 col-lg-12 d-flex justify-content-md-between justify-content-center flex-wrap ">
                            <div className="copyright-text-section my-2">
                                <p>
                                    {new Date().getFullYear()} &copy;
                                    <a href="https://www.hubextech.com/" className="ms-2">
                                        HubexTech&nbsp;
                                    </a>
                                    - All Rights Reserved.
                                </p>
                            </div>
                            <div className="copyright-text-section d-flex flex-wrap my-2">
                                <Link to="/privacypolicy " target="_blank" rel="noreferrer"
                                      className="text-decoration-none me-3">
                                    <p>Privacy Policy</p></Link>
                                <Link to="/termsandconditions" target="_blank" rel="noreferrer"
                                      className="text-decoration-none">
                                    <p>Terms and Conditions</p></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}


export default Footer