import React from 'react'
import './HeroSection.css'
import HsVector from '../../../../Assets/Images/background-hsVector.png'
import {Container, Row, Col} from 'react-bootstrap';
import MainNavbar from "../../../Includes/MainNavbar/MainNavbar";

const HeroSection = () => {
    return (
        <>
            <section className="intro-main">
                <MainNavbar/>
                <Container>
                    <Row className="hero-text">
                        <Col xs={12} md={12} lg={6} className="px-4 mt-5">
                            <div className="mt-5" data-aos={"fade-down"} data-aos-duration={1000} data-aos-delay={100}>
                                <span className="mb-5 display-4 heading-bold"><span
                                    className="intro-desc-text fw-bolder text-white d-block heading-bold">Hire Vetted</span> Engineers Today!</span>
                                <p className='mt-4 fs-5'>
                                    HubexTech is a team of skilled resources dedicated to provide
                                    <br/> customized, scalable, and market-oriented software solutions.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6} className="hs-vector_col" data-aos={"fade-up"}
                             data-aos-duration={1000} data-aos-delay={200}>
                            <img src={HsVector} alt="hs-vector" width="100%"/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HeroSection;